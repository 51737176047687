

html {
  background: #FFF;
  font-size: calc(100vw / 96);
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  @include mq() {
    font-size: 62.5%;
  }


}
body {
  color: $font-color-default;
  font-size: 4rem;
  font-family: $font-family-default;
  line-height: 1.6;
  @include mq(lg) {
    font-size: 1.6rem;
    line-height: 1.7;
  }
  @include mq(sp) {
    font-size: 4rem;

  }
  @include mq(tb) {
    font-size: 2.6rem;
  }

}

img {
  max-width: 100%;
  vertical-align: bottom;
  backface-visibility: hidden;
}
svg {
  vertical-align: middle;
  backface-visibility: hidden;
}

a {
  text-decoration: none;
  color: $font-color-default;
}
.alpha-img {
  img {
    transition: $transition;
  }
  &:hover {
    img {
      opacity: .6;
    }
  }
}
input:not([type="radio"]):not([type="checkbox"]),
button,
select,
textarea {
  -webkit-border-radius: 0;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-appearance: none;
  appearance: none;
  font-family: inherit;
}

.inner {
  max-width: 900px;
  margin: 0 auto;
  padding: 0 10px;
  @include mq(sp) {
    padding: 0 4.16vw;
  }
  &-02 {
   margin: 0 auto;
   max-width: 880px; 
  }
}

svg {
  overflow: visible;
}

.indent {
  li {
    padding-left: 1em;
    text-indent: -1em;
  }
}

.pc {
  @include mq(sp) {
    display: none;
  }
}


.sp {
  @include mq(lg) {
    display: none;
  }
}
