html {
  @include mq(sp) {
    &:before {
      display: block;
      z-index: -1;
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background: url('../img/bg_sec_01_sp.jpg') top center no-repeat;
      background-size: cover;
      background-position: top center;
      content: "";
    }
    &.-bg-2 {
      &:before {
        background-image: url('../img/bg_sec_02_sp.jpg');
      }
    }
    &.-bg-3 {
      &:before {
        background-image: url('../img/bg_sec_03_sp.jpg');
      }
    }
  }
  
}


.parallax {
  &-wrap {
    position: relative;
    @include mq(lg) {
      height: 400px;
    }
    & + section {
      @include mq(sp) {
        margin-top: 50vw;
      }
    }

  }
  &-window { 
    @include mq(lg) {
      position: absolute;
      width: 100%;
      height: 100%;
      overflow: hidden;
      backface-visibility: hidden;
    }
    &-element {
      @include mq(lg) {
        background-repeat: no-repeat;
        position: absolute;
        width: 100%;
        height: 100%;
        background-attachment: fixed;
        backface-visibility: hidden;
        background-position: top center;
        background-size: cover;
      }
    }

  }
}

@media only screen and (orientation:landscape) and (max-width:1024px) {
  .top-sec01 + .parallax-wrap .parallax-window-element {
    background-position: bottom center;
  }
}
