/*
#overview
【Block】
*/
.bg {
  &-white {
    background: #FFF;
  }
  &-gray {
    background: #f6f6f6;
  }
  &-navy {
    background: #071650;
    color: #FFF;
  }
}

.sec {
  &-cmn {//共通セクション
    padding: {
      top: 16.66vw;
      bottom: 16.66vw;
    };
    @include mq() {
      padding: {
        top: 100px;
        bottom: 100px;
      };
    }
  }
  &-md {//セクション中
    padding: {
      top: 12vw;
      bottom: 12vw;
    };
    @include mq() {
      padding: {
        top: 50px;
        bottom: 50px;
      };
    }
  }
  &-sm {//セクション小
    padding: {
      top: 8vw;
      bottom: 8vw;
    };
    @include mq() {
      padding: {
        top: 30px;
        bottom: 30px;
      };
    }
  }
  &-xs {//セクション極小
    padding: {
      top: 4vw;
      bottom: 4vw;
    };
    @include mq() {
      padding: {
        top: 20px;
        bottom: 20px;
      };
    }
  }
}

.box {
  &-cmn {
    padding: 5vw 4vw;
    background: #FFF;
    @include mq() {
      padding: 22px 30px;
    }
    & + & {
      margin-top: 4vw;
      @include mq() {
        margin-top: 20px;
      }
    }
  }
  &-alert {
    @include mq() {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: center;
      padding: 15px 30px;
    }
    &__ico {
      margin-bottom: 2vw;
      text-align: center;
      @include mq() {
        margin: 0 20px 0 0;
      }
    }
  }
}
.display {
  &-block {
    display: block;
  }
  &-inline-block {
    display: inline-block;
  }
  &-inline {
    display: inline;
  }
}