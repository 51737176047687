.modal {
  &-wrap {
    position: relative;
    padding: 10px 10px 25px 10px;
    background: #ffffff;
    border-radius: 4px;
    color: #1f1f1f;
    font-size: 1.6rem;
    margin: 10px auto;
    @include mq(sp) {
      padding: 2.6vw 2.6vw 4vw 2.6vw;
      font-size: 3.2rem;
    }
    &__caption {
      margin-top: 25px;
      @include mq(sp) {
        margin-top: 3.3vw;
      }
    }
    .popup-modal-dismiss {
      position: absolute;
      top: -14px;
      right: -10px;
      width: 30px;
      height: 30px;
      @include mq(tb) {
        top: -34px;
      }
    }
    .mfp-close {
      display: none;
    }
    .popup-modal {
      
    }
  }
}

.gallery-arrow-left,.gallery-arrow-right {
  &:hover {
    cursor: pointer;
  }
  &:before {
    content:'';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: #000;
    z-index: 10000;
    width: 40px;
    height: 40px;
    @include mq(sp) {
      width: 5.3vw;
      height: 5.3vw;
      background-size: cover!important;
    }
  }
}

.gallery-arrow-left:before{
  left: 25px;
  background: url(../img/ico_prev.png) no-repeat 0 0;
  @include mq(sp) {
    left: 8vw;
  }
}

.gallery-arrow-right:before{
  right: 25px;
  background: url(../img/ico_next.png) no-repeat 0 0;
  @include mq(sp) {
    right: 8vw;
  }
}

.mfp-container {
  max-width: 960px;
  left: 50%!important;
  transform: translateX(-50%);
  @media screen and (max-width:1024px) and (min-width:768px) {
    max-width: 800px;
  }
  @include mq(sp) {
    padding-left: 4vw!important;
    padding-right: 4vw!important;
  }
}

.popup-modal-count {
  position: absolute;
  color: #999999;
  font-size: 1.4rem;
  text-align: right;
  bottom: 25px;
  right: 29px;
  @include mq(sp) {
    font-size: 2.8rem;
    bottom: 4vw;
  }
}