.grid {
  @include mq() {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
  }
  &__col02 {
    @include mq() {
      flex-basis: 48%;
      max-width: 48%;
      &:nth-child(2n) {
        margin: 0 0 0 4%;
      }
    }
  }
}