.cmn-heading {
  &-wrap {
    margin: 0 auto 55px;
    text-align: center;
    @include mq(lg) {
      max-width: 460px;
    }
    @include mq(sp) {
      margin: 0 auto 10.2vw;
    }
  }
  &-ttl {
    position: relative;
    margin-top: 30px;
    font-size: 2.8rem;
    line-height: 1.2;
    @include mq(sp) {
      margin-top: 3vw;
      font-size: 5.6rem;
    }
    @include mq(tb) {
      font-size: 3.6rem;
    }
    &:before {
      @include mq(lg) {
        position: absolute;
        width: 100%;
        height: 1px;
        content:"";
        background: url(../img/line_dotted.png) repeat-x 0 0;
        left: 0;
        bottom: 50%;
        transform: translateY(-50%);
      }
    }
    &__inner {
      display: inline-block;
      padding: 0 20px;
      background: #ffffff;
      color: $accentColor;
      font-weight: bold;
      position: relative;
      @include mq(sp) {
        padding: 0 4.16vw;
      }
      &--pattern {
        background: url(../img/bg_menu.png) repeat 0 0;
      }
      @include mq(sp) {
        &:before,&:after {
          position: absolute;
          width: 100%;
          height: 1px;
          content:"";
          background: url(../img/line_dotted.png) repeat-x 0 0;
          bottom: 50%;
        }
      }
      &:before {
        @include mq(sp) {
          right: 100%;
          width: 15.6vw;
        }
      }
      &:after {
        @include mq(sp) {
          left: 100%;
          width: 15.6vw;
        }
      }
    }
  }
  &-ico-element {
    display: block;
    margin: 0 auto;

  }
}