.top-sec03 {
  &-st0{fill-rule:evenodd;clip-rule:evenodd;}
  &-st1{fill:none;}
  &-st2{fill:#4267B2;}
  &-st3{fill:#26190D;}
  &-st4{fill:#221815;}
  &-st5{fill-rule:evenodd;clip-rule:evenodd;fill:#221815;}
  &-st6{fill:#FFFFFF;}
}




.hero {
  position: relative;
  background: url(../img/kv_pc.jpg) no-repeat top center;
  background-size: cover;
  height: 840px;
  @include mq(sp) {
    background: url(../img/kv_sp.jpg) no-repeat top center;
    height: 100vw;
    background-size: 100% auto;
  }
  &__logo {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    
    &-element {
      text-align: center;
      width: 279px;
      fill: #333333;
      @include mq(lg) {
        height: 316px;
      }
      @include mq(sp) {
        width: 50.46vw;
        height: 66.4vw;
        // width: 58.12vw;
        // height: 64.8vw;
      }
    }
  } 
}

.scroll-ico {
  position: absolute;
  bottom: 50px;
  left: 50%;
  margin-left: -16.5px;
  @include mq(sp) {
    bottom: 1vw;
    margin-left: -1.6vw;
  }
  &-arrow {
    width: 33px;
    height: 19px;
    @include mq(sp) {
      width: 5vw;
      height: 4vw;
    }
  }
}

.top {
  &-sec {
    padding: 53px 0 97px;
    min-height:421px;
    @include mq(sp) {
      padding: 10vw 0;
    }
    &-txt {
      p {
        &:nth-child(n+2) {
          margin-top: 20px;
        }
      }
    }
  }
  &-sec01 {
    &__ico {
      width: 55px;
      height: 55px;
      @include mq(sp) {
        width: 16.56vw;
        height: 17.15vw;
      }
    }
  }
  &-sec02 {
    margin: 0;
    &__ico {
      width: 55px;
      height: 55px;
      @include mq(sp) {
        width: 15.56vw;
        height: 16.15vw;
      }
    }
  }
  &-sec03 {
    position: relative;
    padding: 78px 0 80px;
    background: url(../img/bg_menu.png) repeat 0 0;
    @include mq(sp) {
      padding: 8.54vw 0 10.7vw;
    }
    &:before {
      content: "";
      position: absolute;
      background: url(../img/ico_arrow_menu.png) repeat-x 0 0;
      bottom: 100%;
      left: 0;
      width: 100%;
      height: 12px;
    }
    &__ico {
      width: 181px;
      height: 71px;
      @include mq(sp) {
        width: 50.5vw;
        height: 17.18vw;
        margin: 0 auto;
      }
    }
    .small {
      margin-top: 20px;
      font-size: 1.3rem;
      @include mq(tb) {
        font-size: 2.8rem;
      }
      @include mq(sm) {
        font-size: 3.5rem;
      }
    }
  }
  &-sec04 {
    &__ico {
      width: 60px;
      height: 60px;
      @include mq(sp) {
        width: 18.75vw;
        height: 15vw;
      }
    }
  }
  &-sec05 {

    iframe {
      width: 100%;
      max-height: 560px;
      @include mq(sp) {
        max-height: 70vw
      }
    }
  }
}

.material-list {
  margin-top: 20px;
  padding: 20px 30px;
  background: #d7cea2;
  @include mq(sp) {
    font-size: 3.5rem;
  }
  @include mq(tb) {
    font-size: 2.8rem;
  }
  &__ttl {
    font-weight: bold;
  }
  &__box {
    @include mq(lg) {
      display: flex;
      justify-content: space-between;
    }
  }
}



.menu-list {
  display: flex;
  flex-wrap: wrap;
  @include mq(sp) {
    justify-content: center;
  }
  @include mq(sp) {
    justify-content: space-between;
    align-items: center;
  }
  &__items {
    // text-align: center;
    flex-basis: 29.5%;
    padding-bottom: 18px;
    border-bottom: 1px solid #333333;
    @include mq(sp) {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      font-size: 4rem;
      text-align: center;
      @include mq(tb) {
        font-size: 2.6rem;
      }
    }
 
    span {
      @include mq(lg) {
        margin-left: 5px;
      }
      @include mq(sp) {
        display: block;
      }
    }
    a {
      display: block;
      @include mq(md) {
        position: relative;
      }
      @include mq(tb) {
        position: static;
      }
      &:before {
        content:"";
        background: url(../img/loupe.svg) no-repeat 0 0;
        width: 17px;
        height: 17px;
        position: absolute;
        right: 0;
        bottom: 0;
        @include mq(tb) {
          bottom: 40px;
        }
        @include mq(sm) {
          bottom: 5vw;
          right: 10px;
        }
      }
    }

    @include mq(sp) {
      flex-basis: 41.6vw;
      padding-bottom: 1.77vw;
    }
    &:not(:nth-child(3n)) {
      @include mq(lg) {
        margin-right: 5.6%;

      }
    }
    &:nth-child(n+4) {
      @include mq(lg) {
        margin-top: 28px;

      }
    }
    &:nth-child(n+3) {
      @include mq(sp) {
        margin-top: 8.3vw;

      }
    }
    img {
      display: inline-block;
      width: 46px;
      margin-right: 18px;
      @include mq(sp) {
        display: block;
        width: 9.58vw;
        margin: 0 auto;
      }
    }
  }
}

.access {
  &-wrap {
    @include mq(lg) {
      display: flex;
      align-items: flex-end;
      justify-content: center;
    }
  }
  &-photo {
    @include mq(lg) {
      flex-basis: 48%;
      max-width: 480px;
      margin-right: 8%;
    }
  }
  &-info {
    @include mq(sp) {
      margin-top: 3.5vw;
    }
  }
  &-sns {
    display: flex;
    margin-top: 23px;
    @include mq(sp) {
      margin-top: 4.3vw;
    }
    &__items {
      margin-right: 10px;
      @include mq(sp) {
        margin-right: 5vw;
      }
    }
  }
  &-ico {
    width: 28px;
    height: 28px;
    visibility: visible;
    display: block;
    overflow: visible;
    @include mq(sp) {
      width: 6.25vw;
      height: 6.25vw;
    }
    @include mq(tb) {
      width: 6.25vw;
      height: 6.25vw;
    }
    img {
      max-width: 100%;
      overflow: visible;
    }
  }
}

