
//table
.table {
  &-cmn {
    @include table-cmn;
  }
}
.table-layout {
  display: table;
  width: 100%;
  & + & {
    margin-top: 40px;
    @include mq() {
      margin-top: 25px;
    }
  }
  &__item {
    display: table-cell;
    vertical-align: top;
  }
}
