
//ブレイクポイントをマップ型で定義
$breakpoints: (
  'sp': 'screen and (max-width: 1000px)',//スマホ
  'tb': 'screen and (min-width: 768px) and (max-width:1000px)',//タブレット縦～横
  'sm': 'screen and (max-width: 767px)',
  'md': 'screen and (min-width: 768px)',
  'lg': 'screen and (min-width: 1001px)',
  'xl': 'screen and (min-width: 1280px)',
) !default;

$font-color-default: #1f1f1f;

$font-family-default: 'hiragino kaku gothic pro',"游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, Meiryo,sans-serif;

$transition: .3s;


//color
$red:red;
$base-color: #1f1f1f;
$accentColor: #db481d;