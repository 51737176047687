.footer {
  text-align: center;
  padding: 20px 0;
  background: #000000;
  color: #ffffff;
  line-height: 1.2;
}

.page-top {
  display: block;
  position: fixed;
  bottom: 20px;
  right: 0;
  border-radius: 50%;
  background: $accentColor;
  width: 50px;
  height: 50px;
  @include mq(sp) {
    width: 10.4vw;
    height: 10.4vw;
  }
  &:hover {
    .ico-pagetop {
      transform: translate(-50%,-90%)
    }
  }
  @at-root {
    .ico-pagetop {
      transition: .3s;
      position: absolute;
      z-index: 100;
      width: 16px;
      height: 10px;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      @include mq(sp) {
        width: 3.33vw;
        height: 2.08vw;
      }
    }
  }
}