.gnavi {
  &-list {
    display: flex;
    justify-content: center;
    padding: 23px 0;
    @include mq(sp) {
      justify-content: space-between;
      padding: 2.2vw 0 4.1vw;
    }
  }

  &-items {
    text-align: center;
    &:nth-child(n+2) {
      margin-left: 36px;
      @include mq(sp) {
        margin-left: 6.6vw;
      }
    }
    &__link {
      position: relative;
      display: block;
      padding-top: 50px;
      @include mq(sp) {
        padding-top: 9.68vw;
      }
    }
  }
  &-ico {
    position: absolute;
    width: 50px;
    height: 42px;
    top: 0;
    left: 50%;
    margin-left: -25px;
    @include mq(sp) {
      width: 8.54vw;
      height: 7.91vw;
      margin-left: -4.27vw;
    }
  }
  &-name {
    font-size: 1.4rem;
    font-weight: bold;
    @include mq(sp) {
      font-size: 2.6rem;
    }

    @media screen and (orientation:portrait) {
      font-size: 2rem;
    }
    @include mq(sm) {
      font-size: 3rem;
    }
  }
}