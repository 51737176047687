//animation
.fadeIn { opacity: 0;}
.fadeInUp {
  animation-fill-mode:both;
  animation-duration:1.5s;
  animation-name: fadeInUp;
}
@keyframes fadeInUp {
  0% { opacity: 0; transform: translateY(20px);}
  100% { opacity: 1; transform: translateY(0);}
}

.color-change {
  &:hover {
    .color-change--red {
      color: $accentColor;
    }
  }
  &-svg {
    &:hover {
      svg {
        fill: $accentColor;
      }
    }
  }
}

.zoom {
  &:hover {
    @include mq(lg) {
      .zoom-item {
        // transform: scale(1.1);
        width: 55px;
        height: 47px;
      }
    }
  }

}

.anime-opaciy {
  * {
    transition: .3s;
  }
  &:hover {
    * {
      opacity: 0.8;
    }
  }
}

.anime-transition {
  .zoom-item {
    transition: .3s;

  }
}
